import React from "react"

function Table() {
    return (
        <div>
            <h1>Monthly <strong className="purple">Growth</strong> Packages </h1>

            <table className="table">
            <thead>
            <tr>
                <th><strong className="purple">Starter</strong></th>
                <th><strong className="purple">Brand Creation</strong></th>
                <th><strong className="purple">Brand Growth Accelerator</strong></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>3x Sizzle Reels</td>
                <td>7x Sizzle Reels</td>
                <td>10x Sizzle Reels</td>
            </tr>
            <tr>
                <td>10x Edited Photos</td>
                <td>1x Specialized Reels</td>
                <td>2x Specialized Reels</td>
            </tr>
            <tr>
                <td></td>
                <td>Drone Footage</td>
                <td>Advanced FPV Drone Footage</td>
            </tr>
            <tr>
                <td></td>
                <td>15x Edited Photos</td>
                <td>30x Edited Photos</td>
            </tr>
            <tr>
                <td></td>
                <td>4x Social Media Graphics</td>
                <td>10x Social Media Graphics</td>
            </tr>
            <tr>
                <td>$700</td>
                <td>$900</td>
                <td>$1,800</td>
            </tr>
            </tbody>
        </table>    

        </div>
    )
}

export default Table;