import React, { useState, useEffect } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import jsonData from '../../data/info.json';

const name = jsonData.name;

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={0} className="home-header">

              <h1 className="heading-name">
                <span className="welcome-text">Welcome to</span>
                <strong className="main-name">{name}'s Workshop</strong>
              </h1>

              <div className="typewriter-div">
                <p style={{ color: "transparent" }}>|</p> <Type />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
